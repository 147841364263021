import React from 'react'
import { css } from '@emotion/core'
import Button from './Button'
import Input from './Input'
import Textarea from './Textarea'
import Label from './Label'

const Control = (props) => (
    <p
        className={props.className}
        css={css`
            position: relative;
            margin-bottom: 2.5em;
            & input ~ label {
                position: absolute;
                bottom: 0.25em;
                transform-origin: left bottom;
                transition: transform 0.2s ease;
            }
            & input:focus ~ label,
            & input:not([value=""]) ~ label {
                transform: translate3d(0,calc( -100% - 0.25em),0) scale(0.9);
            }
        `}
    >{props.children}</p>
)

export default () => (
    <form name="contact" method="POST" action="/thanks/" data-netlify="true"
        css={css`
            max-width: 40em;
            margin-right: auto;
            margin-left: auto;
            @media ( min-width: 48em ) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                p {
                    width: calc(50% - 0.75em);
                    margin-bottom: 1em;
                }
                p.wide {
                    width: 100%;
                }
            }
        `}
    >
        <input type="hidden" name="form-name" value="contact" />
        <Control>
            <Input id="contact-form-name" type="text" name="name" required />
            <Label for="contact-form-name">Name</Label>
        </Control>
        <Control>
            <Input id="contact-form-email" type="email" name="email" required />
            <Label for="contact-form-email">Email</Label>
        </Control>
        <Control>
            <Input id="contact-form-age" type="text" name="age" />
            <Label for="contact-form-age">Age of Child(ren)</Label>
        </Control>
        <Control>
            <Input id="contact-form-county" type="text" name="county" />
            <Label for="contact-form-county">In what county do you live?</Label>
        </Control>
        <Control className="wide">
            <Label for="contact-form-message">Message</Label>
            <Textarea id="contact-form-message" name="message" required />
        </Control>
        <Control className="wide">
            <Input id="contact-form-source" type="text" name="source" />
            <Label for="contact-form-source">How did you hear about Acton Academy Upstate?</Label>
        </Control>
        <p>
            <button type="submit"><Button>Send</Button></button>
        </p>
    </form>
)
