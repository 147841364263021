import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'
import PageWrapper from '../components/PageWrapper'
import ContactForm from '../components/ContactForm'

export default ({ data }) => (
    <Layout>
        <Helmet>
            <title>Contact Us | {data.site.siteMetadata.title}</title>
        </Helmet>
        <main>
            <article>
                <PageHeader title="Contact Us" />
                <PageWrapper>
                    <ContactForm />
                </PageWrapper>
            </article>
        </main>
    </Layout>
)
export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
